.footer{
    display: flex;
     
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -7rem;
    
    background-color: var(--orange);
}
.f-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-top:1rem;
   
    color: white;
    /* background-color: var(--orange); */
    height: 1rem;
     gap: 51rem;


}
.f-content span  {
    margin-bottom:1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
}
@media screen and (max-width:480px){
    .f-content {
        gap: 5rem; 
               
    } 
    .f-content span  {
        margin-bottom:1rem;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: smaller;
    }
       
}
 