/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
:root
{
  --yellow:#F5C32C;
  --orange:#FCA61F;
  --black:#242D49;
  --gray:#788697;
  --blueCard:#DDF8FE;
  --purple:rgb(238, 210, 255);
  --boxShadow:0px 19px 60px rgb(0 0 0 / 8%);
  --orangeCard:rgba(228, 145, 13, 0.747);
  --smboxShadow:-79px 51px 60px rgba(0,0,0,0.08);
}

.App{
  padding:0.5rem 3.5rem;
  overflow:hidden;
  color:var(--black);
  /* background-color: rgba(0, 0, 255, 0.596); */
}

.button{
  border-radius: 34px;
  border: none;
  color:white;
  font-size: 16px;
  padding: 11px 26px 11px 26px ;
  background: linear-gradient(180deg,#fdc50f 26.71%,#fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
}

.button:hover{
  background: white;
  cursor: pointer;
  color: var(--orange);
  border: 1px solid var(--orange);
}

@media screen and (max-width:480px){
.App{
  padding: 0.5rem 1rem;
}

}